// store
import Store from './store/Store';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <Store>
      <ThemeConfig>
        <ScrollToTop />
        <Router />
      </ThemeConfig>
    </Store>
  );
}
