import { useState, useEffect } from 'react';
// material
import { styled } from '@material-ui/core/styles';
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Tooltip,
    IconButton
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import externalLink from '@iconify/icons-eva/external-link-outline';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import { PossibleMismatchListHead, PossibleMismatchListToolbar } from 'src/components/_dashboard/possibleMismatch';
//
import api from "src/api/Api";
import urls from "src/api/Urls";
import authHeader from "src/api/AuthHeader";
import { fDateTime } from 'src/utils/formatTime';
import { fNumberFixed, fPercent } from 'src/utils/formatNumber';
import moment from 'moment';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'created', label: 'Captured At', alignRight: false, sortable: true },
    { id: 'total_arbitrage', label: 'Profit', alignRight: false, sortable: true },
    { id: 'league', label: 'League', alignRight: false, sortable: false },
    { id: 'event', label: 'Event', alignRight: false, sortable: false },
    { id: 'base_event', label: 'Base Event', alignRight: false, sortable: false },
    { id: 'bet_type', label: 'Bet Type', alignRight: false, sortable: false },
    { id: 'provider', label: 'Providers', alignRight: false, sortable: false },
    { id: 'party', label: 'Pick', alignRight: false, sortable: false },
    { id: 'action', label: 'Open Related', alignRight: false, sortable: false },
];


const StyledContainer = styled(Container)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        minWidth: "400px",
        maxWidth: "600px",
    },
    [theme.breakpoints.up('md')]: {
        minWidth: "1500px",
        maxWidth: "1700px",
    }
}));

// ----------------------------------------------------------------------

export default function PossibleMismatches({ sureBetType }) {
    let url, title;
    if (sureBetType === "player") {
        url = urls.playerPossibleMismatchDetails;
        title = "Player Possible Mismatches";
    } else {
        url = urls.teamPossibleMismatchDetails;
        title = "Team Possible Mismatches";
    }
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [orderData, setOrderData] = useState({ order: "total_arbitrage", direction: "desc" });
    const [formData, setFormData] = useState({});

    const openRelatedLinks = (links) => (event) => {
        Object.keys(links).map(key => {
            console.log(key);
            window.open(links[key], key);
        });
    };

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getEvent = (fixture1, fixture2) => {
        if (sureBetType === "team") {
            return `${fixture1.provider_team} - ${fixture2.provider_team}`;
        } else {
            return (
                <Stack direction="column" alignItems="left" spacing={1}>
                    <Typography variant="p" noWrap>
                        {fixture1.provider_home_team} - {fixture1.provider_away_team}
                    </Typography>
                    <Typography variant="p" noWrap>
                        {fixture2.provider_away_team} - {fixture2.provider_home_team}
                    </Typography>
                </Stack>
            );
        }
    }

    const getBaseEvent = (fixture1, fixture2) => {
        if (sureBetType === "team") {
            return (
                fixture1.base_team && fixture1.base_team.includes(fixture1.base_team_city) ?
                    `${fixture1.base_team} - ${fixture2.base_team}` :
                    `${fixture1.base_team_city} ${fixture1.base_team} - ${fixture2.base_team_city} ${fixture2.base_team}`
            );
        } else {
            let firstBaseEvent;
            let secondBaseEvent;
            if (fixture1.base_home_team && fixture1.base_home_team.includes(fixture1.base_home_team_city)) {
                firstBaseEvent = `${fixture1.base_home_team} - ${fixture1.base_away_team}`;
                secondBaseEvent = `${fixture2.base_away_team} - ${fixture2.base_home_team}`;
            } else {
                firstBaseEvent = `${fixture1.base_home_team_city} ${fixture1.base_home_team} - ${fixture1.base_away_team_city} ${fixture1.base_away_team}`;
                secondBaseEvent = `${fixture2.base_away_team_city} ${fixture2.base_away_team} - ${fixture2.base_home_team_city} ${fixture2.base_home_team}`;
            }
            return (
                <Stack direction="column" alignItems="left" spacing={1}>
                    <Typography variant="p" noWrap>
                        {firstBaseEvent}
                    </Typography>
                    <Typography variant="p" noWrap>
                        {secondBaseEvent}
                    </Typography>
                </Stack>
            );
        }
    }

    const updateItems = () => {
        setIsLoading(true);
        const params = {
            ...formData,
            ...orderData,
            page: page + 1,
            page_size: rowsPerPage,
        };
        if (params.from_date) {
            api.get(url, { headers: authHeader(), params: params })
                .then(response => {
                    setTotalCount(response.data.count);
                    setItems(response.data.items);
                    setIsLoading(false);
                })
        }
    }

    useEffect(() => {
        updateItems();
    }, [sureBetType, page, rowsPerPage, formData, orderData]);

    return (
        <Page title={`${title} | Quantbet Admin`}>
            <StyledContainer>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {title}
                    </Typography>
                </Stack>

                <Card>
                    <PossibleMismatchListToolbar setFormData={setFormData} sureBetType={sureBetType} />
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <PossibleMismatchListHead
                                    headLabels={TABLE_HEAD}
                                    rowCount={items.length}
                                    orderData={orderData}
                                    setOrderData={setOrderData}
                                />
                                <TableBody>
                                    {items
                                        .map((row, idx) => {
                                            const { created, league, bet_type, total_arbitrage, fixture1, fixture2, links } = row;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={idx}
                                                    tabIndex={-1}
                                                >
                                                    <TableCell align="left">{fDateTime(created * 1000)}</TableCell>
                                                    <TableCell>
                                                        <Label
                                                            variant="ghost"
                                                            color="success"
                                                        >
                                                            {fPercent((100 / (total_arbitrage / 100)) - 100)}
                                                        </Label>
                                                    </TableCell>
                                                    <TableCell align="left">{league}</TableCell>
                                                    <TableCell align="left">{getEvent(fixture1, fixture2)}</TableCell>
                                                    <TableCell align="left">{getBaseEvent(fixture1, fixture2)}</TableCell>
                                                    <TableCell align="left">{bet_type}</TableCell>
                                                    <TableCell align="left" component="th" scope="row">
                                                        <Stack direction="column" alignItems="left" spacing={1}>
                                                            <Typography variant="p" noWrap>
                                                                {fixture1.provider}
                                                            </Typography>
                                                            <Typography variant="p" noWrap>
                                                                {fixture2.provider}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <Stack direction="column" alignItems="left" spacing={1}>
                                                            <Typography variant="p" noWrap>
                                                                {sureBetType === "team" ? fixture1.provider_team : `${fixture1.provider_player} - ${fixture2.provider_player}`}
                                                            </Typography>
                                                            {sureBetType === "team" ? <Typography variant="p">
                                                                {fixture2.provider_team}
                                                            </Typography>
                                                                : fixture1.player_base_team && fixture1.player_base_team.includes(fixture1.player_base_team_city) ?
                                                                    `${fixture1.player_base_team} - ${fixture2.player_base_team}` :
                                                                    `(${fixture1.player_base_team_city} ${fixture1.player_base_team}) - (${fixture2.player_base_team_city} ${fixture2.player_base_team})`
                                                            }
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Tooltip style={{ cursor: "pointer" }} title="Open in new tabs">
                                                            <IconButton onClick={openRelatedLinks(links)}>
                                                                <Icon icon={externalLink} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[10, 25]}
                        component="div"
                        count={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </StyledContainer>
        </Page>
    );
}
