import { useState, useEffect } from 'react';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@material-ui/core/styles';
import { Card, CardHeader } from '@material-ui/core';
//
import { BaseOptionChart } from '../../charts';
import api from "src/api/Api";
import urls from "src/api/Urls";
import authHeader from "src/api/AuthHeader";
import moment from 'moment';
import { API_DATETIME_FORMAT } from 'src/utils/formatTime';
// ----------------------------------------------------------------------

const CHART_HEIGHT = 392;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(2),
  '& .apexcharts-canvas svg': {
    height: CHART_HEIGHT
  },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));

// ----------------------------------------------------------------------


export default function AppCurrentSubject() {
  const categories = ['NBA', 'NFL', 'NHL', 'MLB'];
  const [teamSurebetChartData, setTeamChartData] = useState([]);
  const [playerSurebetChartData, setPlayerChartData] = useState([]);
  const theme = useTheme();

  const toDate = moment().format(API_DATETIME_FORMAT);
  const startDate = moment().subtract(1, 'days').format(API_DATETIME_FORMAT);

  const groupByResponseData = (data, totalKey) => {
    let dataArray = [0, 0, 0, 0];
    for (const d of data) {
      if (categories.includes(d.name)) {
        const idx = categories.indexOf(d.name);
        dataArray[idx] = dataArray[idx] + d[totalKey];
      }
    }
    return dataArray;
  }

  const updateTeamData = () => {
    api.get(urls.teamSureBetDaily, { headers: authHeader(), params: { from_date: startDate, to_date: toDate } })
      .then(response => {
        const data = groupByResponseData(response.data, "teamsurebet_total");
        setTeamChartData(data);
      })
  }

  const updatePlayerData = () => {
    api.get(urls.playerSureBetDaily, { headers: authHeader(), params: { from_date: startDate, to_date: toDate } })
      .then(response => {
        const data = groupByResponseData(response.data, "playersurebet_total");
        setPlayerChartData(data);
      })
  }

  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: 2 },
    fill: { opacity: 0.48 },
    legend: { floating: true, horizontalAlign: 'center' },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          colors: [
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary,
          ]
        }
      }
    }
  });

  useEffect(() => {
    updateTeamData();
    updatePlayerData();
  }, []);

  return (
    <Card>
      <CardHeader title="Daily Surebets By Leagues" />
      <ChartWrapperStyle dir="ltr">
        <ReactApexChart type="radar" series={[
          { name: 'Team Surebet', data: teamSurebetChartData },
          { name: 'Player Surebet', data: playerSurebetChartData }
        ]} options={chartOptions} height={340} />
      </ChartWrapperStyle>
    </Card>
  );
}
