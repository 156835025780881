import { useState, useEffect } from 'react';
// material
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination
} from '@material-ui/core';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { CrawlerStatListHead, CrawlerStatListToolbar } from 'src/components/_dashboard/crawlerStat';
//
import api from "src/api/Api";
import urls from "src/api/Urls";
import authHeader from "src/api/AuthHeader";
import { fDateTime } from 'src/utils/formatTime';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'start_time', label: 'Started At', alignRight: false, sortable: true },
    { id: 'provider', label: 'Providers', alignRight: false, sortable: false },
    { id: 'error_count', label: 'Error Count', alignRight: true, sortable: true },
    { id: 'item_scraped_count', label: 'Item Count', alignRight: true, sortable: true },
];

// ----------------------------------------------------------------------

export default function CrawlerStats({ }) {
    const title = "Crawler Stats";
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [orderData, setOrderData] = useState({ order: "start_time", direction: "desc" });
    const [formData, setFormData] = useState({});

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const updateItems = () => {
        setIsLoading(true);
        const params = {
            ...formData,
            ordering: orderData.direction === "asc" ? orderData.order : `-${orderData.order}`,
            page: page + 1,
            page_size: rowsPerPage,
        };
        if (params.start_time && params.end_time) {
            api.get(urls.crawlerStats, { headers: authHeader(), params: params })
                .then(response => {
                    setTotalCount(response.data.count);
                    setItems(response.data.results);
                    setIsLoading(false);
                })
        }
    }

    useEffect(() => {
        updateItems();
    }, [page, rowsPerPage, formData, orderData]);

    return (
        <Page title={`${title} | Quantbet Admin`}>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {title}
                    </Typography>
                </Stack>

                <Card>
                    <CrawlerStatListToolbar setFormData={setFormData} />
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <CrawlerStatListHead
                                    headLabels={TABLE_HEAD}
                                    rowCount={items.length}
                                    orderData={orderData}
                                    setOrderData={setOrderData}
                                />
                                <TableBody>
                                    {items
                                        .map((row, idx) => {
                                            const { start_time, provider, error_count, item_scraped_count } = row;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={idx}
                                                    tabIndex={-1}
                                                >
                                                    <TableCell align="left">{fDateTime(+start_time * 1000)}</TableCell>
                                                    <TableCell align="left">{provider}</TableCell>
                                                    <TableCell align="right">{error_count}</TableCell>
                                                    <TableCell align="right">{item_scraped_count}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[10, 25]}
                        component="div"
                        count={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Page>
    );
}
