import PropTypes from 'prop-types';
// material
import { TableRow, TableCell, TableHead, TableSortLabel } from '@material-ui/core';

// ----------------------------------------------------------------------

ProviderLeagueListHead.propTypes = {
    headLabels: PropTypes.array,
};

export default function ProviderLeagueListHead({
    headLabels,
}) {
    return (
        <TableHead>
            <TableRow>
                {headLabels.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        sortDirection={false}
                    >
                        <TableSortLabel
                            hideSortIcon
                            active={false}
                            disabled={true}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
