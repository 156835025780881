import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import filterIcon from '@iconify/icons-eva/funnel-fill';
import moment from 'moment';
// material
import { styled } from '@material-ui/core/styles';
import { useMediaQuery, Toolbar, Tooltip, IconButton, Stack, FormControl, InputLabel, FormHelperText, TextField, Select, MenuItem } from '@material-ui/core';
import { DateTimePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
// app
import { getBaseLeagues, getBaseBetTypes } from "src/api/services/baseEntities";
import { API_DATETIME_FORMAT } from "src/utils/formatTime";
// ----------------------------------------------------------------------

const isMiddleMapping = [
    { name: 'Yes', id: 'true' },
    { name: 'No', id: 'false' },
];

const RootStyle = styled(Toolbar)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
    [theme.breakpoints.down('md')]: {
        height: 450
    },
    [theme.breakpoints.up('md')]: {
        height: 120
    }
}));

const FormControlSelectStyle = styled(FormControl)(({ theme }) => ({
    minWidth: 150,
}));

const FormHelperTextStyle = styled(FormHelperText)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        marginBottom: "-5px",
    },
    [theme.breakpoints.up('md')]: {
        marginBottom: "-20px",
    }
}));

// ----------------------------------------------------------------------

SureBetListToolbar.propTypes = {
    setFormData: PropTypes.func,
    sureBetType: PropTypes.string,
};

export default function SureBetListToolbar({ setFormData, sureBetType }) {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    const defaultStartDate = moment().subtract(7, "days");
    const defaultEndDate = moment();
    const [baseLeagues, setBaseLeagues] = useState([]);
    const [baseBetTypes, setBaseBetTypes] = useState([]);
    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);
    const [dateError, setDateError] = useState("");
    const [league, setLeague] = useState("");
    const [betType, setBetType] = useState("");
    const [isMiddle, setIsMiddle] = useState("");

    const transformFormData = (event) => {
        if (dateError === "") {
            setFormData({
                from_date: startDate.format(API_DATETIME_FORMAT),
                to_date: endDate.format(API_DATETIME_FORMAT),
                base_bet_type_id: betType ? betType : undefined,
                base_league_id: league ? league : undefined,
                is_middle: isMiddle ? isMiddle : undefined,
                page: 0,
            });
        }
    }

    const handleLeagueChange = (event) => {
        setLeague(event.target.value);
    }

    const handleBetTypeChange = (event) => {
        setBetType(event.target.value);
    }

    const handleIsMiddleChange = (event) => {
        setIsMiddle(event.target.value);
    }

    const updateFilterItems = () => {
        getBaseBetTypes().then(response => {
            setBaseBetTypes(response.data.results);
        });
        getBaseLeagues().then(response => {
            setBaseLeagues(response.data.results);
        });
    }

    const checkDateRange = () => {
        const dayDiff = endDate.diff(startDate, 'seconds');
        if (dayDiff <= 0) {
            setDateError("Start time should be earlier than the end time");
        } else if (dayDiff > (30 * 24 * 60 * 60)) {
            setDateError("Max allowed date range is 30 days");
        } else {
            setDateError("");
        }
    }

    useEffect(() => {
        updateFilterItems();
    }, []);

    useEffect(() => {
        transformFormData();
    }, []);

    useEffect(() => {
        checkDateRange();
    }, [startDate, endDate]);

    return (
        <RootStyle
            sx={{
                color: 'primary.main',
                bgcolor: 'primary.lighter'
            }}
        >
            <Stack spacing={3} direction={isSmallScreen ? "column" : "row"}>
                <FormControl>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            label="Start Date-Time"
                            value={startDate}
                            onChange={(newValue) => { setStartDate(moment(newValue)); }}
                        />
                    </LocalizationProvider>
                    <FormHelperTextStyle>{dateError}</FormHelperTextStyle>
                </FormControl>
                <FormControl>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            label="End Date-Time"
                            value={endDate}
                            onChange={(newValue) => { setEndDate(moment(newValue)); }}
                        />
                    </LocalizationProvider>
                </FormControl>
                <FormControlSelectStyle>
                    <InputLabel >League</InputLabel>
                    <Select
                        label={"League"}
                        value={league}
                        onChange={handleLeagueChange}
                    >
                        <MenuItem value="">
                            <em>All</em>
                        </MenuItem>
                        {baseLeagues.map((item, i) =>
                            <MenuItem value={item.id} key={i}>
                                {item.name}
                            </MenuItem>
                        )}
                    </Select>
                </FormControlSelectStyle>
                <FormControlSelectStyle>
                    <InputLabel >Bet Type</InputLabel>
                    <Select
                        label={"Bet Type"}
                        value={betType}
                        onChange={handleBetTypeChange}
                    >
                        <MenuItem value="">
                            <em>All</em>
                        </MenuItem>
                        {baseBetTypes.map((item, i) => {
                            if ((sureBetType === "player" && item.is_player_based) || (sureBetType === "team" && !item.is_player_based)) {
                                return (<MenuItem value={item.id} key={i}>
                                    {item.name}
                                </MenuItem>);
                            }
                        })}
                    </Select>
                </FormControlSelectStyle>
                <FormControlSelectStyle>
                    <InputLabel >Middle</InputLabel>
                    <Select
                        label={"Middle"}
                        value={isMiddle}
                        onChange={handleIsMiddleChange}
                    >
                        <MenuItem value="">
                            <em>All</em>
                        </MenuItem>
                        {isMiddleMapping.map((item, i) =>
                            <MenuItem value={item.id} key={i}>
                                {item.name}
                            </MenuItem>
                        )}
                    </Select>
                </FormControlSelectStyle>
            </Stack>
            <Tooltip style={{ cursor: dateError ? "not-allowed" : "pointer" }} title="Filter">
                <IconButton onClick={transformFormData}>
                    <Icon icon={filterIcon} />
                </IconButton>
            </Tooltip>
        </RootStyle>
    );
}
