import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import personFill from '@iconify/icons-eva/person-fill';
import questionMarkFill from '@iconify/icons-eva/question-mark-circle-fill';
import questionMarkOutline from '@iconify/icons-eva/question-mark-circle-outline';
import barChartFill from '@iconify/icons-eva/bar-chart-fill';
import infoOutline from '@iconify/icons-eva/info-outline';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill),
    adminOnly: false,
    addDividerAfter: true
  },
  {
    title: 'users',
    path: '/dashboard/users',
    icon: getIcon(peopleFill),
    adminOnly: true
  },
  {
    title: 'team surebets',
    path: '/dashboard/surebets/team',
    icon: getIcon(peopleFill),
    adminOnly: false
  },
  {
    title: 'player surebets',
    path: '/dashboard/surebets/player',
    icon: getIcon(personFill),
    adminOnly: false,
    addDividerAfter: true,
  },
  {
    title: 'team matching',
    path: '/dashboard/basematching/team',
    icon: getIcon(peopleFill),
    adminOnly: false
  },
  {
    title: 'player matching',
    path: '/dashboard/basematching/player',
    icon: getIcon(personFill),
    adminOnly: false,
    addDividerAfter: true,
  },
  {
    title: 'team surebet mismatches',
    path: '/dashboard/surebets/mismatches-team',
    icon: getIcon(questionMarkFill),
    adminOnly: true
  },
  {
    title: 'player surebet mismatches',
    path: '/dashboard/surebets/mismatches-player',
    icon: getIcon(questionMarkOutline),
    adminOnly: true,
    addDividerAfter: true,
  },
  {
    title: 'crawler stats',
    path: '/dashboard/crawler-stats',
    icon: getIcon(barChartFill),
    adminOnly: false,
    addDividerAfter: true,
  },
  {
    title: 'provider league matrix',
    path: '/dashboard/provider-leagues',
    icon: getIcon(infoOutline),
    adminOnly: false,
    addDividerAfter: true,
  },
];

export default sidebarConfig;
