import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Alert,
  Snackbar,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

import { useStore } from 'src/store/Store';
import api from "src/api/Api";
import urls from "src/api/Urls";
import authHeader from "src/api/AuthHeader";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [loginSuccessful, setLoginSuccessful] = useState(true);
  const [store, dispatch] = useStore();
  const navigate = useNavigate();
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      (async () => {
        const loginResponse = await api.post(urls.login, { username: values.email, password: values.password });
        if (loginResponse.status === 200 && loginResponse.data.is_staff === true) {
          const token = loginResponse.data.token;
          const profileResponse = await api.get(urls.profile, { headers: authHeader(token) });
          if (profileResponse.status === 200) {
            const user = profileResponse.data.results[0];
            await dispatch({
              type: "LOGIN",
              payload: {
                token: token,
                user: {
                  ...user,
                  isStaff: loginResponse.data.is_staff,
                  isSuperUser: loginResponse.data.is_superuser
                }
              }
            });
          }
        } else {
          actions.setSubmitting(false);
          setLoginSuccessful(false);
        }
      })();
      navigate('/dashboard', { replace: true });
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={!loginSuccessful} autoHideDuration={6000} onClose={() => setLoginSuccessful(true)}>
        <Alert onClose={() => setLoginSuccessful(true)} severity="error">
          User not found
        </Alert>
      </Snackbar>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>
          <br />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Login
          </LoadingButton>
        </Form>
      </FormikProvider >
    </>
  );
}
