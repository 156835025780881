import { useState, useEffect } from 'react';
// material
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination
} from '@material-ui/core';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import { SureBetListHead, SureBetListToolbar } from 'src/components/_dashboard/surebet';
//
import api from "src/api/Api";
import urls from "src/api/Urls";
import authHeader from "src/api/AuthHeader";
import { fDateTime } from 'src/utils/formatTime';
import { fNumberFixed, fPercent } from 'src/utils/formatNumber';
import moment from 'moment';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'created', label: 'Captured At', alignRight: false, sortable: true },
    { id: 'total_arbitrage', label: 'Profit', alignRight: false, sortable: true },
    { id: 'league', label: 'League', alignRight: false, sortable: false },
    { id: 'event', label: 'Event', alignRight: false, sortable: false },
    { id: 'bet_type', label: 'Bet Type', alignRight: false, sortable: false },
    { id: 'provider', label: 'Providers', alignRight: false, sortable: false },
    { id: 'party', label: 'Pick', alignRight: false, sortable: false },
    { id: 'line', label: 'Lines', alignRight: true, sortable: false },
    { id: 'over', label: 'Over/Under', alignRight: false, sortable: false },
    { id: 'odd', label: 'Odds', alignRight: true, sortable: false }
];

// ----------------------------------------------------------------------

export default function SureBets({ sureBetType }) {
    let url, title;
    if (sureBetType === "player") {
        url = urls.playerSureBetDetails;
        title = "Player SureBets";
    } else {
        url = urls.teamSureBetDetails;
        title = "Team SureBets";
    }
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [orderData, setOrderData] = useState({ order: "total_arbitrage", direction: "desc" });
    const [formData, setFormData] = useState({});

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const updateItems = () => {
        setIsLoading(true);
        const params = {
            ...formData,
            ...orderData,
            page: page + 1,
            page_size: rowsPerPage,
        };
        if (params.from_date) {
            api.get(url, { headers: authHeader(), params: params })
                .then(response => {
                    setTotalCount(response.data.count);
                    setItems(response.data.items);
                    setIsLoading(false);
                })
        }
    }

    useEffect(() => {
        updateItems();
    }, [sureBetType, page, rowsPerPage, formData, orderData]);

    return (
        <Page title={`${title} | Quantbet Admin`}>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {title}
                    </Typography>
                </Stack>

                <Card>
                    <SureBetListToolbar setFormData={setFormData} sureBetType={sureBetType} />
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <SureBetListHead
                                    headLabels={TABLE_HEAD}
                                    rowCount={items.length}
                                    orderData={orderData}
                                    setOrderData={setOrderData}
                                />
                                <TableBody>
                                    {items
                                        .map((row, idx) => {
                                            const { created, league, bet_type, total_arbitrage, fixture1, fixture2 } = row;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={idx}
                                                    tabIndex={-1}
                                                >
                                                    <TableCell align="left">{fDateTime(created * 1000)}</TableCell>
                                                    <TableCell>
                                                        <Label
                                                            variant="ghost"
                                                            color="success"
                                                        >
                                                            {fPercent((100 / (total_arbitrage / 100)) - 100)}
                                                        </Label>
                                                    </TableCell>
                                                    <TableCell align="left">{league}</TableCell>
                                                    <TableCell align="left">{`${fixture1.team} - ${fixture2.team}`}</TableCell>
                                                    <TableCell align="left">{bet_type}</TableCell>
                                                    <TableCell align="left" component="th" scope="row">
                                                        <Stack direction="column" alignItems="left" spacing={1}>
                                                            <Typography variant="p" noWrap>
                                                                {fixture1.provider}
                                                            </Typography>
                                                            <Typography variant="p" noWrap>
                                                                {fixture2.provider}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <Stack direction="column" alignItems="left" spacing={1}>
                                                            <Typography variant="p" noWrap>
                                                                {sureBetType === "team" ? fixture1.team : fixture1.player}
                                                            </Typography>
                                                            {sureBetType === "team" ? <Typography variant="p" noWrap>
                                                                {fixture2.team}
                                                            </Typography>
                                                                : null}
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <Stack direction="column" alignItems="right" spacing={1}>
                                                            <Typography variant="p" noWrap>
                                                                {fNumberFixed(fixture1.line, 1)}
                                                            </Typography>
                                                            <Typography variant="p" noWrap>
                                                                {fNumberFixed(fixture2.line, 1)}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <Stack direction="column" alignItems="center" spacing={1}>
                                                            <Typography variant="p" noWrap>
                                                                {fixture1.over ? "Over" : fixture1.under ? "Under" : ""}
                                                            </Typography>
                                                            <Typography variant="p" noWrap>
                                                                {fixture2.over ? "Over" : fixture2.under ? "Under" : ""}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell align="right" component="th" scope="row">
                                                        <Stack direction="column" alignItems="right" spacing={1}>
                                                            <Typography variant="p" noWrap>
                                                                {fNumberFixed(fixture1.odd)}
                                                            </Typography>
                                                            <Typography variant="p" noWrap>
                                                                {fNumberFixed(fixture2.odd)}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[10, 25]}
                        component="div"
                        count={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Page>
    );
}
