import PropTypes from 'prop-types';
// material
import { visuallyHidden } from '@material-ui/utils';
import { Box, Checkbox, TableRow, TableCell, TableHead, TableSortLabel } from '@material-ui/core';

// ----------------------------------------------------------------------

SureBetListHead.propTypes = {
    rowCount: PropTypes.number,
    headLabels: PropTypes.array,
    orderData: PropTypes.object,
};

export default function SureBetListHead({
    rowCount,
    headLabels,
    orderData,
    setOrderData,
}) {
    const sortHandler = (column) => (event) => {
        let direction = 'asc';
        if (orderData.order === column) {
            if (orderData.direction === direction) {
                direction = 'desc';
            };
        }
        const newOrder = { order: column, direction: direction };
        setOrderData(newOrder);
    }
    return (
        <TableHead>
            <TableRow>
                {headLabels.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? 'right' : 'left'}
                        sortDirection={orderData.order === headCell.id ? orderData.direction : false}
                    >
                        <TableSortLabel
                            hideSortIcon
                            active={orderData.order === headCell.id}
                            direction={orderData.direction}
                            onClick={sortHandler(headCell.id)}
                            disabled={headCell.sortable ? false : true}
                        >
                            {headCell.label}
                            {orderData.order === headCell.id ? (
                                <Box sx={{ ...visuallyHidden }}>
                                    {orderData.direction === 'asc' ? 'sorted ascending' : 'sorted descending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
