export default function authHeader(token = null) {
    if (token === null) {
        token = localStorage.getItem("token");
    }

    if (token) {
        return { Authorization: "Token " + token };
    } else {
        return {};
    }
}
