// material
import { styled } from '@material-ui/core/styles';
import { useMediaQuery, Toolbar, Stack } from '@material-ui/core';
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
    [theme.breakpoints.down('md')]: {
        height: 60
    },
    [theme.breakpoints.up('md')]: {
        height: 60
    }
}));

// ----------------------------------------------------------------------

export default function ProviderLeagueListToolbar({ }) {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));

    return (
        <RootStyle
            sx={{
                color: 'primary.main',
                bgcolor: 'primary.lighter'
            }}
        >
            <Stack spacing={3} direction={isSmallScreen ? "column" : "row"}>
            </Stack>
        </RootStyle>
    );
}
