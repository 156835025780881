import api from "src/api/Api";
import urls from "src/api/Urls";
import authHeader from "src/api/AuthHeader";


export function getProviders() {
    return api.get(urls.providers, { headers: authHeader() });
}

export function getBaseBetTypes() {
    return api.get(urls.baseBetTypes, { headers: authHeader() });
}

export function getBaseLeagues() {
    return api.get(urls.baseLeagues, { headers: authHeader() });
}

export function getBaseTeams(league = undefined, name = undefined) {
    const params = { league: league, name_query: name, page_size: 100 }
    return api.get(urls.baseTeams, { headers: authHeader(), params: params });
}

export function getBasePlayers(league = undefined, name = undefined) {
    const params = { league: league, name_query: name, page_size: 100 }
    return api.get(urls.basePlayers, { headers: authHeader(), params: params });
}
