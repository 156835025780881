import axios from "axios";


const instance = axios.create({
    baseURL: "https://api.quantbet.space/api/v1/",
    headers: {
        "Content-Type": "application/json",
    }
});

instance.interceptors.response.use((response) => {
    return response;
}, error => {
    if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        window.location = "/";
    }
    return error;
});

export default instance;
