import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import filterIcon from '@iconify/icons-eva/funnel-fill';
// material
import { styled } from '@material-ui/core/styles';
import { useMediaQuery, Toolbar, Tooltip, IconButton, Stack, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
// app
import { getBaseLeagues } from "src/api/services/baseEntities";
// ----------------------------------------------------------------------

const isMatchedMapping = [
    { name: 'Yes', id: 'false' },
    { name: 'No', id: 'true' },
];

const RootStyle = styled(Toolbar)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
    [theme.breakpoints.down('md')]: {
        height: 200
    },
    [theme.breakpoints.up('md')]: {
        height: 120
    }
}));

const FormControlSelectStyle = styled(FormControl)(({ theme }) => ({
    minWidth: 150,
}));

// ----------------------------------------------------------------------

BaseMatchingListToolbar.propTypes = {
    setFormData: PropTypes.func,
    baseType: PropTypes.string,
};

export default function BaseMatchingListToolbar({ setFormData, baseType }) {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    const [baseLeagues, setBaseLeagues] = useState([]);
    const [league, setLeague] = useState("");
    const [matched, setMatched] = useState("");

    const transformFormData = (event) => {
        let formData = {
            page: 0,
            league: league ? league : undefined,
        };
        if (baseType === "player") {
            formData["base_team_player"] = matched ? matched : undefined;
        } else {
            formData["base_team"] = matched ? matched : undefined;
        }
        setFormData(formData);
    }

    const handleLeagueChange = (event) => {
        setLeague(event.target.value);
    }

    const handleIsMatchedChange = (event) => {
        setMatched(event.target.value);
    }

    const updateFilterItems = () => {
        getBaseLeagues().then(response => {
            setBaseLeagues(response.data.results);
        });
    }

    useEffect(() => {
        updateFilterItems();
    }, []);

    useEffect(() => {
        transformFormData();
    }, []);

    return (
        <RootStyle
            sx={{
                color: 'primary.main',
                bgcolor: 'primary.lighter'
            }}
        >
            <Stack spacing={3} direction={isSmallScreen ? "column" : "row"}>
                <FormControlSelectStyle>
                    <InputLabel >League</InputLabel>
                    <Select
                        label={"League"}
                        value={league}
                        onChange={handleLeagueChange}
                    >
                        <MenuItem value="">
                            <em>All</em>
                        </MenuItem>
                        {baseLeagues.map((item, i) =>
                            <MenuItem value={item.name} key={i}>
                                {item.name}
                            </MenuItem>
                        )}
                    </Select>
                </FormControlSelectStyle>
                <FormControlSelectStyle>
                    <InputLabel >Matched</InputLabel>
                    <Select
                        label={"Matched"}
                        value={matched}
                        onChange={handleIsMatchedChange}
                    >
                        <MenuItem value="">
                            <em>All</em>
                        </MenuItem>
                        {isMatchedMapping.map((item, i) =>
                            <MenuItem value={item.id} key={i}>
                                {item.name}
                            </MenuItem>
                        )}
                    </Select>
                </FormControlSelectStyle>
            </Stack>
            <Tooltip title="Filter">
                <IconButton onClick={transformFormData}>
                    <Icon icon={filterIcon} />
                </IconButton>
            </Tooltip>
        </RootStyle>
    );
}
