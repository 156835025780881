import { useState, useEffect } from 'react';
// material
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Modal,
    Box,
    Tooltip,
    IconButton,
    Alert,
    Snackbar,
    FormControl,
    Button,
    Grid,
    Divider,
    Autocomplete,
    TextField,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
// components
import Page from '../components/Page';
import editFill from '@iconify/icons-eva/edit-fill';
import saveOutline from '@iconify/icons-eva/save-outline';
import Scrollbar from '../components/Scrollbar';
import { BaseMatchingListHead, BaseMatchingListToolbar } from 'src/components/_dashboard/baseMatching';
//
import api from "src/api/Api";
import urls from "src/api/Urls";
import authHeader from "src/api/AuthHeader";
import { fPercent } from 'src/utils/formatNumber';
import { getBaseTeams, getBasePlayers } from "src/api/services/baseEntities";
// ----------------------------------------------------------------------

const TABLE_HEAD_PLAYER = [
    { id: 'league', label: 'League', alignRight: false, sortable: false },
    { id: 'provider', label: 'Provider', alignRight: false, sortable: false },
    { id: 'fullname', label: 'Player', alignRight: false, sortable: false },
    { id: 'base_fullname', label: 'Base Player', alignRight: false, sortable: false },
    { id: 'base_team_name', label: 'Base Team', alignRight: false, sortable: false },
    { id: 'mapping_data__ratio', label: 'Similarity', alignRight: true, sortable: true },
    { id: 'edit', label: 'Edit', alignRight: false, sortable: false },
];

const TABLE_HEAD_TEAM = [
    { id: 'league', label: 'League', alignRight: false, sortable: false },
    { id: 'provider', label: 'Provider', alignRight: false, sortable: false },
    { id: 'name', label: 'Team', alignRight: false, sortable: false },
    { id: 'base_name', label: 'Base Team', alignRight: false, sortable: false },
    { id: 'mapping_data__ratio', label: 'Similarity', alignRight: true, sortable: true },
    { id: 'edit', label: 'Edit', alignRight: false, sortable: false },
];


const StyledBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    backgroundColor: 'white',
    boxShadow: 24,
    padding: "30px",
    [theme.breakpoints.down('md')]: {
        top: '30%',
        left: '30%',
        transform: 'translate(-30%, -30%)',
        minWidth: 400,
    },
    [theme.breakpoints.up('md')]: {
        top: '30%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 600,
    }
}));

const FormControlSelectStyle = styled(FormControl)(({ theme }) => ({
    width: "100%",
    marginTop: "20px",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: "red",
}));


// ----------------------------------------------------------------------

export default function SureBets({ baseType }) {
    let url, title, tableHead;
    if (baseType === "player") {
        url = urls.providerPlayers;
        title = "Player Matching";
        tableHead = TABLE_HEAD_PLAYER;
    } else {
        url = urls.providerTeams;
        title = "Team Matching";
        tableHead = TABLE_HEAD_TEAM;
    }
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [orderData, setOrderData] = useState({ order: "mapping_data__ratio", direction: "desc" });
    const [formData, setFormData] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState({ id: 0, name: "", first_name: "", last_name: "", league: "" });
    const [baseItems, setBaseItems] = useState([]);
    const [newBaseId, setNewBaseId] = useState(0);

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const prepModal = (row) => (event) => {
        setSelectedRow(row);
        setModalOpen(true);
    };

    const handleModalClose = (event) => {
        setModalOpen(false);
    };

    const updateItems = () => {
        setIsLoading(true);
        const params = {
            ...formData,
            ...{ ordering: orderData.direction === "desc" ? "-" + orderData.order : orderData.order },
            page: page + 1,
            page_size: rowsPerPage,
        };
        api.get(url, { headers: authHeader(), params: params })
            .then(response => {
                setTotalCount(response.data.count);
                setItems(response.data.results);
                setIsLoading(false);
            })
    }

    const handleNewBaseIdChange = (event, data) => {
        if (data.id) {
            setNewBaseId(data.id);
        }
    }

    const handleBaseEdit = (event) => {
        if (newBaseId > 0) {
            const url = baseType === "player" ? urls.updateProviderPlayer(selectedRow.id) : urls.updateProviderTeam(selectedRow.id);
            const params = baseType === "player" ? { base_team_player: newBaseId } : { base_team: newBaseId };
            api.put(url, params, { headers: authHeader() }).then(response => {
                if (response.status === 200) {
                    updateItems();
                    setAlertOpen(true);
                    setModalOpen(false);
                }
                else { console.log("something went wrong"); }
            });
        }
    }

    useEffect(() => {
        updateItems();
    }, [baseType, page, rowsPerPage, formData, orderData]);

    useEffect(() => {
        const { league, first_name, last_name, name } = selectedRow;
        if (baseType === "player") {
            getBasePlayers(league, `${first_name} ${last_name}`).then(response => {
                const items = response.data.results.map(item => { return { ...item, label: `${item.first_name} ${item.last_name} (${item.team_name}) (${fPercent(item.similarity <= 1 ? item.similarity * 100 : item.similarity)})` } });
                setBaseItems(items);
            });
        }
        else {
            getBaseTeams(league, name).then(response => {
                const items = response.data.results.map(item => { return { ...item, label: `${item.code} - ${item.city} ${item.name} (${fPercent(item.similarity <= 1 ? item.similarity * 100 : item.similarity)})` } });
                setBaseItems(items);
            });
        }
    }, [selectedRow]);

    return (
        <Page title={`${title} | Quantbet Admin`}>
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "bottom" }} message="Base entity has been updated successfully!" open={alertOpen} autoHideDuration={6000} onClose={() => { setAlertOpen(false) }}>

            </Snackbar>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <StyledBox sx={{ flexGrow: 1 }}>
                    {selectedRow.mapping_data && (selectedRow.mapping_data.ratio === 1 || selectedRow.mapping_data.ratio === 100) ?
                        <>
                            <StyledTypography>
                                This is a perfect match. Are you sure you want to edit this entity?
                            </StyledTypography>
                            <Divider style={{ marginBottom: "5px" }} />
                        </>
                        : null}
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {baseType === "player" ? "Select Base Player" : "Select Base Team"}
                    </Typography>
                    <Typography id="modal-modal-title" variant="p" component="p">
                        {baseType === "player" ? `${selectedRow.first_name} ${selectedRow.last_name}` : selectedRow.name}
                    </Typography>
                    <Grid container spacing={2} direction="column">
                        <Grid item>
                            <FormControlSelectStyle>
                                <Autocomplete
                                    id="base-items"
                                    options={baseItems}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => <TextField {...params} label="Base" />}
                                    onChange={handleNewBaseIdChange}
                                />
                            </FormControlSelectStyle>
                        </Grid>
                        <Grid item style={{ marginLeft: "auto" }}>
                            <FormControl>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    startIcon={<Icon icon={saveOutline} />}
                                    onClick={handleBaseEdit}
                                >
                                    Save
                                </Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                </StyledBox>
            </Modal>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {title}
                    </Typography>
                </Stack>

                <Card>
                    <BaseMatchingListToolbar setFormData={setFormData} baseType={baseType} />
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <BaseMatchingListHead
                                    headLabels={tableHead}
                                    rowCount={items.length}
                                    orderData={orderData}
                                    setOrderData={setOrderData}
                                />
                                <TableBody>
                                    {items
                                        .map((row, idx) => {
                                            const { league, provider_name, base_team_name, base_team_city, base_first_name, base_last_name, first_name, last_name, mapping_data, name } = row;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={idx}
                                                    tabIndex={-1}
                                                >
                                                    <TableCell align="left">{league}</TableCell>
                                                    <TableCell align="left">{provider_name}</TableCell>
                                                    {baseType === "player" ?
                                                        <>
                                                            <TableCell align="left">{first_name} {last_name}</TableCell>
                                                            <TableCell align="left">{base_first_name} {base_last_name}</TableCell>
                                                            <TableCell align="left">{base_team_city} {base_team_name}</TableCell>
                                                        </>
                                                        :
                                                        <>
                                                            <TableCell align="left">{name}</TableCell>
                                                            <TableCell align="left">{base_team_city} {base_team_name}</TableCell>
                                                        </>
                                                    }
                                                    <TableCell align="right"> {mapping_data && mapping_data.ratio ? fPercent(mapping_data.ratio <= 1 ? mapping_data.ratio * 100 : mapping_data.ratio) : "-"} </TableCell>
                                                    <TableCell align="left">
                                                        <Tooltip style={{ cursor: "pointer" }} title="Edit">
                                                            <IconButton onClick={prepModal(row)}>
                                                                <Icon icon={editFill} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[10, 25]}
                        component="div"
                        count={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Page>
    );
}
