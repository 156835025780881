// material
import { Grid, Container } from '@material-ui/core';
// components
import Page from '../components/Page';
import {
  AppWeeklySubscriptionRenewals,
  AppWeeklyRevenue,
  AppCurrentMemberCount,
  AppWeeklyNewSubscriptions,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppCurrentSubject,
  AppConversionRates
} from '../components/_dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  return (
    <Page title="Dashboard | Quantbet Admin">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklyNewSubscriptions />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySubscriptionRenewals />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppCurrentMemberCount />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklyRevenue />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
