import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import filterIcon from '@iconify/icons-eva/funnel-fill';
import moment from 'moment';
// material
import { styled } from '@material-ui/core/styles';
import { useMediaQuery, Toolbar, Tooltip, IconButton, Stack, FormControl, InputLabel, FormHelperText, TextField, Select, MenuItem } from '@material-ui/core';
import { DateTimePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
// app
import { getProviders } from "src/api/services/baseEntities";
import { API_DATETIME_FORMAT } from "src/utils/formatTime";
// ----------------------------------------------------------------------


const RootStyle = styled(Toolbar)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
    [theme.breakpoints.down('md')]: {
        height: 450
    },
    [theme.breakpoints.up('md')]: {
        height: 120
    }
}));

const FormControlSelectStyle = styled(FormControl)(({ theme }) => ({
    minWidth: 150,
}));

const FormHelperTextStyle = styled(FormHelperText)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        marginBottom: "-5px",
    },
    [theme.breakpoints.up('md')]: {
        marginBottom: "-20px",
    }
}));

// ----------------------------------------------------------------------

CrawlerStatListToolbar.propTypes = {
    setFormData: PropTypes.func,
};

export default function CrawlerStatListToolbar({ setFormData }) {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    const defaultStartDate = moment().subtract(1, "days");
    const defaultEndDate = moment();
    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);
    const [dateError, setDateError] = useState("");
    const [selectedProvider, setSelectedProvider] = useState("");
    const [providers, setProviders] = useState([]);

    const transformFormData = (event) => {
        if (dateError === "") {
            setFormData({
                start_time: startDate.format(API_DATETIME_FORMAT),
                end_time: endDate.format(API_DATETIME_FORMAT),
                provider_id: selectedProvider && selectedProvider > 0 ? selectedProvider : undefined,
                page: 0,
            });
        }
    }

    const handleSelectedProviderChange = (event) => {
        setSelectedProvider(+event.target.value);
    }

    const updateFilterItems = () => {
        getProviders().then(response => {
            setProviders(response.data);
        });
    }

    const checkDateRange = () => {
        const dayDiff = endDate.diff(startDate, 'seconds');
        if (dayDiff <= 0) {
            setDateError("Start time should be earlier than the end time");
        } else if (dayDiff > (30 * 24 * 60 * 60)) {
            setDateError("Max allowed date range is 30 days");
        } else {
            setDateError("");
        }
    }

    useEffect(() => {
        updateFilterItems();
    }, []);

    useEffect(() => {
        transformFormData();
    }, []);

    useEffect(() => {
        checkDateRange();
    }, [startDate, endDate]);

    return (
        <RootStyle
            sx={{
                color: 'primary.main',
                bgcolor: 'primary.lighter'
            }}
        >
            <Stack spacing={3} direction={isSmallScreen ? "column" : "row"}>
                <FormControl>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            label="Start Date-Time"
                            value={startDate}
                            onChange={(newValue) => { setStartDate(moment(newValue)); }}
                        />
                    </LocalizationProvider>
                    <FormHelperTextStyle>{dateError}</FormHelperTextStyle>
                </FormControl>
                <FormControl>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            label="End Date-Time"
                            value={endDate}
                            onChange={(newValue) => { setEndDate(moment(newValue)); }}
                        />
                    </LocalizationProvider>
                </FormControl>
                <FormControlSelectStyle>
                    <InputLabel >Provider</InputLabel>
                    <Select
                        label={"Provider"}
                        value={selectedProvider}
                        onChange={handleSelectedProviderChange}
                    >
                        <MenuItem value="">
                            <em>All</em>
                        </MenuItem>
                        {providers.map((item, i) =>
                            <MenuItem value={item.id} key={i}>
                                {item.name}
                            </MenuItem>
                        )}
                    </Select>
                </FormControlSelectStyle>
            </Stack>
            <Tooltip style={{ cursor: dateError ? "not-allowed" : "pointer" }} title="Filter">
                <IconButton onClick={transformFormData}>
                    <Icon icon={filterIcon} />
                </IconButton>
            </Tooltip>
        </RootStyle>
    );
}
