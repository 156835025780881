const urls = {
    login: "login/",
    profile: "users/",
    playerSureBetDaily: "player_bet_statistics/playersurebet_counts_between/",
    playerSureBetDetails: "player_bet_statistics/playersurebet_details_between/",
    playerPossibleMismatchDetails: "player_bet_statistics/playersurebet_possible_mismatches_between/",
    teamSureBetDaily: "team_bet_statistics/teamsurebet_counts_between/",
    teamSureBetDetails: "team_bet_statistics/teamsurebet_details_between/",
    teamPossibleMismatchDetails: "team_bet_statistics/teamsurebet_possible_mismatches_between/",
    providers: "providers/",
    baseBetTypes: "base_bet_types/",
    baseLeagues: "base_leagues/",
    providerPlayers: "provider_players/",
    providerTeams: "provider_teams/",
    basePlayers: "base_players/",
    baseTeams: "base_teams/",
    updateProviderTeam: (id) => { return `provider_teams/${id}/`; },  // PUT
    updateProviderPlayer: (id) => { return `provider_players/${id}/`; },  // PUT
    crawlerStats: "crawler_stats/",
    providerLeagues: "provider_leagues/",
    userManagement: "user_management",
}

export default urls;
