import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import DashboardApp from './pages/DashboardApp';
import Users from './pages/Users';
import SureBets from './pages/SureBets';
import PossibleMismatches from './pages/PossibleMismatches';
import BaseMatching from './pages/BaseMatching';
import CrawlerStat from './pages/CrawlerStat';
import ProviderLeagues from './pages/ProviderLeagues';
import NotFound from './pages/Page404';
import { useStore } from 'src/store/Store';
// ----------------------------------------------------------------------

export default function Router() {
  const [store, dispatch] = useStore();
  return useRoutes([
    {
      path: '/dashboard',
      element: store.token !== null ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'users', element: store.user && store.user.isSuperUser ? <Users /> : <Navigate to="/dashboard" /> },
        { path: 'surebets/team', element: <SureBets sureBetType="team" /> },
        { path: 'surebets/player', element: <SureBets sureBetType="player" /> },
        { path: 'surebets/mismatches-team', element: store.user && store.user.isSuperUser ? <PossibleMismatches sureBetType="team" /> : <Navigate to="/dashboard" /> },
        { path: 'surebets/mismatches-player', element: store.user && store.user.isSuperUser ? <PossibleMismatches sureBetType="player" /> : <Navigate to="/dashboard" /> },
        { path: 'basematching/team', element: <BaseMatching baseType="team" /> },
        { path: 'basematching/player', element: <BaseMatching baseType="player" /> },
        { path: 'crawler-stats', element: <CrawlerStat /> },
        { path: 'provider-leagues', element: <ProviderLeagues /> },
      ]
    },
    {
      path: '/',
      element: store.token === null ? <LogoOnlyLayout /> : <Navigate to="/dashboard" />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
