import { useState, useEffect } from 'react';
// material
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import SuccessIcon from '@iconify/icons-eva/checkmark-circle-fill';
import FailIcon from '@iconify/icons-eva/close-circle-outline';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { ProviderLeagueListHead, ProviderLeagueListToolbar } from 'src/components/_dashboard/providerLeague';
//
import api from "src/api/Api";
import urls from "src/api/Urls";
import authHeader from "src/api/AuthHeader";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'provider', label: 'Provider', align: "center", sortable: false },
    { id: 'NBA', label: 'NBA', align: "center", sortable: false },
    { id: 'NHL', label: 'NHL', align: "center", sortable: false },
    { id: 'NFL', label: 'NFL', align: "center", sortable: false },
    { id: 'MLB', label: 'MLB', align: "center", sortable: false },
    { id: 'NCAAF', label: 'NCAAF', align: "center", sortable: false },
];

// ----------------------------------------------------------------------

export default function ProviderLeagues({ }) {
    const title = "Provider League Matrix";
    const [isLoading, setIsLoading] = useState(true);
    const [baseLeagues, setBaseLeagues] = useState([]);
    const [items, setItems] = useState([]);

    const updateItems = () => {
        setIsLoading(true);
        api.get(urls.providerLeagues, { headers: authHeader() })
            .then(response => {
                setItems(response.data.results);
                setIsLoading(false);
            })
    }

    const getStatusIconPerLeague = (league, leagues) => {
        return (
            leagues.includes(league) ?
                <TableCell align="center"><Icon style={{ fontSize: "20px", color: "green" }} icon={SuccessIcon} /></TableCell> :
                <TableCell align="center"><Icon style={{ fontSize: "20px", color: "red" }} icon={FailIcon} /></TableCell>
        );
    }

    useEffect(() => {
        updateItems();
    }, []);

    return (
        <Page title={`${title} | Quantbet Admin`}>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {title}
                    </Typography>
                </Stack>

                <Card>
                    <ProviderLeagueListToolbar />
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <ProviderLeagueListHead
                                    headLabels={TABLE_HEAD}
                                />
                                <TableBody>
                                    {items
                                        .map((row, idx) => {
                                            const { provider, leagues } = row;
                                            return (
                                                <TableRow hover key={idx} tabIndex={-1}>
                                                    <TableCell align="center">{provider}</TableCell>
                                                    {getStatusIconPerLeague("NBA", leagues)}
                                                    {getStatusIconPerLeague("NHL", leagues)}
                                                    {getStatusIconPerLeague("NFL", leagues)}
                                                    {getStatusIconPerLeague("MLB", leagues)}
                                                    {getStatusIconPerLeague("NCAAF", leagues)}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                </Card>
            </Container>
        </Page>
    );
}
